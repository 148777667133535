<template>
  <base-section id="pro-features">
    <a id="Discover" />
    <base-section-heading :title="$t('themeFeature.mainTitle')">
      {{ $t("themeFeature.line1") }}<br />
      {{ $t("themeFeature.line2") }}<br />
      {{ $t("themeFeature.line3") }}
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="(feature, i) in features" :key="i" cols="12" md="6">
          <kdw-avatar-card v-bind="feature" align="left" horizontal />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionThemeFeatures",

  data: () => ({
    features: [
      {
        color: "sunflower",
        dark: true,
        title: "themeFeature.title3",
        icon: "mdi-currency-eur-off",
        text: "themeFeature.text3",
      },
      {
        title: "themeFeature.title1",
        icon: "mdi-fountain-pen-tip",
        text: "themeFeature.text1",
      },
      {
        title: "themeFeature.title7",
        icon: "mdi-account-key",
        text: "themeFeature.text7",
      },
      {
        title: "themeFeature.title8",
        icon: "mdi-calendar",
        text: "themeFeature.text8",
      },
      {
        title: "themeFeature.title5",
        icon: "mdi-folder-pound",
        text: "themeFeature.text5",
      },
      {
        title: "themeFeature.title4",
        icon: "mdi-image-size-select-actual",
        text: "themeFeature.text4",
      },
      {
        title: "themeFeature.title9",
        icon: "mdi-web",
        text: "themeFeature.text9",
      },
      {
        title: "themeFeature.title10",
        icon: "mdi-star",
        text: "themeFeature.text10",
      },
      {
        title: "themeFeature.title2",
        icon: "mdi-cellphone",
        text: "themeFeature.text2",
      },
      {
        title: "themeFeature.title6",
        icon: "mdi-help-circle-outline",
        text: "themeFeature.text6",
      },
    ],
  }),
};
</script>
